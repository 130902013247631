
function Time({ time }) {
    return (
        <div>
            {time}
        </div>
    );
}

export default Time;
